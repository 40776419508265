import React from "react";
import Services from "../components/homepage/Services";
import Layout from "../components/layout";

export default function services() {
  return (
    <div>
      <Layout>
        <Services />
      </Layout>
    </div>
  );
}
